import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { AuthenticationService } from '../guards/auth.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
    name: string = null;
    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(public layoutService: LayoutService, private authenticationService: AuthenticationService) { }
   
    ngOnInit() {
        var userDetail = this.authenticationService.getUserDetails();
        if (userDetail){
            this.name = this.toTitleCase(userDetail.name);
        }
    }

    logout(){
        this.authenticationService.logout();
        window.location.reload();
    }

    get themeMode(): boolean {
        return this.layoutService.config().ripple;
    }
    set themeMode(_val: boolean) {
        var themeName = 'lara-light-teal'
        var color = 'light'
        if (_val) 
            themeName = 'lara-dark-teal'
            color = 'dark'

        console.log(themeName)
        this.theme = themeName;
        this.colorScheme = color;
        // this.layoutService.config.update((config) => ({
        //     ...config,
        //     theme: themeName,
        //     colorScheme: color
        // }));
    }


    set theme(val: string) {
        this.layoutService.config.update((config) => ({
            ...config,
            theme: val,
        }));
    }
    get theme(): string {
        return this.layoutService.config().theme;
    }

    set colorScheme(val: string) {
        this.layoutService.config.update((config) => ({
            ...config,
            colorScheme: val,
        }));
    }
    get colorScheme(): string {
        return this.layoutService.config().colorScheme;
    }

    changeTheme(theme: string, colorScheme: string) {
        this.theme = theme;
        this.colorScheme = colorScheme;
    }

    toTitleCase(str: string): string {
        return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
      }
}
