import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';

import { environment } from 'src/environments/environment';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUserOceanica')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/auth/login`, { email, password })
      .pipe(map(response => {
        // assume response.token contains the JWT token
        console.log('Response:', response);
        const token = response.accessToken;
        const decoded = jwtDecode(token) as any; // Use jwt-decode to parse the JWT

        // Save the entire token to localStorage or session as per your requirement
        localStorage.setItem('currentUserOceanica', JSON.stringify({ token }));
        // Additionally, save the extracted details
        console.log('Decoded:', decoded);
        localStorage.setItem('userDetailsOceanica', JSON.stringify({
          name: decoded.name,
          email: decoded.email,
          country: decoded.country,
          phoneNumber: decoded.PhoneNumber,
          role: decoded.role,
          userName: decoded.username
        }));

        this.currentUserSubject.next({ token, ...decoded });
        return { token, ...decoded };
      }));
  }

  signup(account: User): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/signup`, account)
      .pipe(
        mergeMap(() => this.login(account.email, account.password)),
        map(data => ({ success: true, error: null, data })),
        catchError(error => {
          let errorMessages = [];
          if (error.error.errors) {
            for (let key in error.error.errors) {
              errorMessages.push(...error.error.errors[key]);
            }
          } else {
            errorMessages.push(error.error.message || error.statusText);
          }
          return of({ success: false, error: errorMessages, data: null });
        })
      );
  }

  getUserDetails() {
    try {
      const userDetail = JSON.parse(localStorage.getItem('userDetailsOceanica'));
      return userDetail
    } catch (error) {
      return null;
    }
  }

  setPermissions(role: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/permissions/role/${role}`)
      .pipe(map(permissions => {
        localStorage.setItem('permissions', JSON.stringify(permissions));
      }));
  }

  getRole(): string | null {
    const userDetails = this.getUserDetails();
    return userDetails && userDetails.role ? userDetails.role.name : null;
  }

  getPermissions(): string[] {
    return JSON.parse(localStorage.getItem('permissions') || '[]');
  }

  logout() {
    // Remove user from local storage to log user out
    localStorage.removeItem('currentUserOceanica');
    localStorage.removeItem('userDetailsOceanica');
    this.currentUserSubject.next(null);
    window.location.href = '/auth/login';
  }
}
