import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AuthenticationService } from '../guards/auth.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(
        public layoutService: LayoutService,
        private authService: AuthenticationService
    ) { }

    ngOnInit() {
        const userRole = this.authService.getRole();
        this.model = [
            {
                label: 'Home',
                items: [
                    { label: 'Painel', icon: 'pi pi-fw pi-home', routerLink: ['/'] , visible: true}
                ]
            },
            {
                label: 'Conta',
                items: [
                    { label: 'Cotações', icon: 'pi pi-fw pi-id-card', routerLink: ['/quotations'] },
                    { label: 'Propostas', icon: 'pi pi-fw pi-id-card', routerLink: ['/proposals'] },
                    { label: 'Apólices', icon: 'pi pi-fw pi-id-card', routerLink: ['/policies'] },
                    
                ]
            }           
        ];

        if (userRole == 'SuperAdmin'){
            const superAdminMenu = {
                label: 'Gerenciamento',
                items: [
                    { label: 'Empresas', icon: 'pi pi-fw pi-building', routerLink: ['/management/companies'], visible: userRole === 'SuperAdmin' },
                    { label: 'Usuários', icon: 'pi pi-fw pi-user', routerLink: ['/management/users'], visible: userRole === 'SuperAdmin' },
                    { label: 'Permissões', icon: 'pi pi-fw pi-bars', routerLink: ['/management/roles'], visible: userRole === 'SuperAdmin' },
                    
                ]
            }
            this.model.push(superAdminMenu);
        }
    }
}
