import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';
import { Role } from './models/role';
import { RoleEnum } from './models/enum/role.enum';

const routes: Routes = [
    {
        path: '', component: AppLayoutComponent,
        children: [
            { path: '', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },
           
            { path: 'quotations', loadChildren: () => import('./components/quotation/quotation.module').then(m => m.QuotationModule) },
            { path: 'quotation/:quotationId', loadChildren: () => import('./components/quotation-editor/quotation-editor.module').then(m => m.QuotationEditorModule) },
            { path: 'proposals', loadChildren: () => import('./components/proposal/proposal.module').then(m => m.ProposalModule) },
            { path: 'proposal/:proposalId', loadChildren: () => import('./components/proposal-editor/proposal-editor.module').then(m => m.ProposalEditorModule) },
            { path: 'policies', loadChildren: () => import('./components/policy/policy.module').then(m => m.PolicyModule) },
            { path: 'policy/:policyId', loadChildren: () => import('./components/policy-editor/policy-editor.module').then(m => m.PolicyEditorModule) }
           
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {expectedRoles: [RoleEnum.Admin, RoleEnum.SuperAdmin,RoleEnum.Assessoria,RoleEnum.Colaborador,RoleEnum.Corretora,RoleEnum.Produtor] }
    },
    {
        path: '', component: AppLayoutComponent,
        children: [
            { path: '', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'account', loadChildren: () => import('./components/account/account.module').then(m => m.AccountModule) },
            { path: 'management', loadChildren: () => import('./components/management/management.module').then(m => m.ManagementModule) },
            { path: 'policy', loadChildren: () => import('./components/policy/policy.module').then(m => m.PolicyModule) },
            { path: 'quotations', loadChildren: () => import('./components/quotation/quotation.module').then(m => m.QuotationModule) },
            { path: 'quotation/:quotationId', loadChildren: () => import('./components/quotation-editor/quotation-editor.module').then(m => m.QuotationEditorModule) },
            { path: 'proposals', loadChildren: () => import('./components/proposal/proposal.module').then(m => m.ProposalModule) },
            { path: 'proposal/:proposalId', loadChildren: () => import('./components/proposal-editor/proposal-editor.module').then(m => m.ProposalEditorModule) },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {expectedRoles: [RoleEnum.Admin, RoleEnum.SuperAdmin] }
    },
    { path: 'auth', loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule) },
    { path: 'landing', loadChildren: () => import('./components/landing/landing.module').then(m => m.LandingModule) },
    { path: 'notfound', component: NotfoundComponent },
    { path: 'print', loadChildren: () => import('./components/print/print.module').then(m => m.PrintModule) },
    { path: '**', redirectTo: '/notfound' },
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
  })

export class AppRoutingModule {
}
