<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img style="height: 3.5rem;" src="{{layoutService.getLogo()}}" alt="logo">
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">        
        <p style="margin-top:13px" *ngIf="name">{{name}}</p>
        <a href="/account/profile" class="p-link layout-topbar-button">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </a>
        <a href="#" (click)="logout()" class="p-link layout-topbar-button">
            <i class="pi pi-sign-out"></i>
            <span>Profile</span>
        </a>
        <!-- <div style="padding: 9px;">
            <p-inputSwitch [(ngModel)]="themeMode"></p-inputSwitch>
        </div> -->
        
    </div>
</div>